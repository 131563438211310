<template>
  <div>
    <div class="settings-cont clearfix">
      <div class="setting-left l">
        <div class="avator-wapper">
          <div class="avator-mode">
            <img class="avator-img" :src="userinfo.avatar" width="92" height="92"/>
            <div class="update-avator" style="bottom: -30px"><p><a href="javascript:void(0);" class="js-avator-link">更换头像</a></p></div>
          </div>
          <div class="des-mode">
            <p>{{ userinfo.username }}</p>
            <span>ID: {{ userinfo.id }}</span>
            <dl class="js-auth-list clearfix auth-list">
              <dd :class="{ active: userinfo.phonenumber }"><i class="icon-set_phone"></i></dd>
             <!-- <dd :class="{ active: UserPage.UserMessage.emil }"><i class="icon-set_email"></i></dd>-->
            </dl>
          </div>
        </div>
        <div class="list-wapper">
          <h2>账户管理</h2>
          <div class="line"></div>
         <!-- <ul class="menu">
            <li v-for="(v, i) in List" :key="i">
              <a @click="activeName = v" :class="{ on: activeName == v }">{{ v }}<span class="arr"><i class="icon-right2"></i></span></a>
            </li>
          </ul>-->
        </div>
      </div>
      <div class="setting-right">
        <div v-show="activeName == '个人信息'" class="setting-right-wrap wrap-boxes settings">
          <div class="formBox">
            <div class="setting-wrap setting-profile">
              <div class="common-title">个人信息<a @click="xxshow = true" href="javascript: void(0);" class="pull-right js-edit-info">

              <!--  <i class="icon-note"></i>编辑-->
              </a>
              </div>
              <div class="line"></div>
              <div class="info-wapper">
                <div class="info-box clearfix"><label class="pull-left">昵称</label><div class="pull-left">{{ userinfo.username }}</div></div>
               <!-- <div class="info-box clearfix"><label class="pull-left">职位</label><div class="pull-left">{{ userinfo.username || "未设置" }}</div></div>
                <div class="info-box clearfix"><label class="pull-left">城市</label><div class="pull-left">{{ userinfo.username || "未设置" }}</div></div>-->
                <div class="info-box clearfix"><label class="pull-left">性别</label><div class="pull-left">{{ userinfo.sex || "未设置" }}</div></div>
               <!-- <div class="info-box clearfix"><label class="pull-left">个性签名</label><div class="pull-left">{{ userinfo.username|| "未设置" }}</div></div>-->
              </div>
            </div>
          </div>
        </div>


        <div v-show="activeName == '账号绑定'" class="page-settings">
          <div class="common-title">账号绑定<span class="title-tips">完成<b class="color-red">4/4</b></span><a href="#/help" class="pull-right" target="_blank"><i class="icon-ques-revert"></i>常见问题</a></div>
          <div class="line"></div>
          <div class="setting">
            <div class="contentBox">
              <div class="bingd">
                <div class="itemBox">
                  <div class="left"><i class="icon-set_email"></i></div>
                  <div class="center">
                    <p><span class="font1">邮箱</span><span class="font3">33********@qq.com<input class="hide" id="jsMail" value="33********@qq.com"/>已绑定</span></p>
                    <p class="font2">可用邮箱加密码登录p8课堂，可用邮箱找回密码</p>
                  </div>
                  <div class="right"><a href="javascript:;" class="change js-change moco-btn moco-btn-normal" changetype="email" @click="cz.isEmile = true">更改</a></div>
                </div>
                <div class="itemBox">
                  <div class="left"><i class="icon-set_phone"></i></div>
                  <div class="center">
                    <p><span class="font1">手机</span><span class="font4" id="jsPhone">186******91</span></p>
                    <p class="font2">可用手机号加密码登录p8课堂，可通过手机号找回密码<a class="font2" style="text-decoration: underline" href="//www.imooc.com/help/detail/78" target="_blank">无法绑定</a></p>
                  </div>
                  <div class="right"><a href="javascript:void(0);" class="change js-operate-phone moco-btn moco-btn-normal" changetype="phone" @click="cz.isPone = true">操作</a><input type="hidden" id="account" value="186******91" /></div>
                </div>

                <div class="itemBox">
                  <div class="left"><i class="icon-set_key"></i></div>
                  <div class="center"><p><span class="font1">密码</span></p><p class="font2">用于保护账号信息和登录安全</p></div>
                  <div class="right"><a @click="cz.isPass = true" href="javascript:void(0);" class="moco-btn moco-btn-normal js-changePWD">重设</a></div>
                </div>
                <div class="itemBox bb0 h380">
                  <div class="left"><i class="icon-feedback"></i></div>
                  <div class="center">
                    <p class="font1">社交账号</p>
                    <p class="font2">绑定第三方账号，可以直接登录，还可以将内容同步到以下平台，与更多好友分享</p>
                    <div class="accountBox">
                      <div class="inner-i-box">
                        <!-- color_blue -->
                        <i class="icon-weixin"></i>
                        <p class="mr87 bind-name">微信</p>
                        <p class="mr87 red">未绑定</p>
                        <a data-unbind="weixin" class="mr87 moco-btn-normal rlf-btn-green btn" href="javascript:void(0)">添加绑定</a>
                      </div>
                      <div class="inner-i-box">
                        <i class="icon-weibo"></i>
                        <p class="mr87 bind-name">微博</p>
                        <p class="mr87 red">未绑定</p>
                        <a href="#/passport/user/tplogin?tp=weibo&amp;bind=1" aria-role="button" hidefocus="true" class="moco-btn-normal rlf-btn-green btn js-bind mr87">添加绑定</a>
                      </div>
                      <div class="inner-i-box">
                        <i class="icon-qq"></i>
                        <p class="mr87 bind-name">QQ</p>
                        <p class="mr87 red">未绑定</p>
                        <a href="#/passport/user/tplogin?tp=qq&amp;bind=1" aria-role="button" hidefocus="true" class="moco-btn-normal rlf-btn-green btn js-bind mr87">添加绑定</a>
                      </div>
                    </div>
                  </div>
                  <div class="right"></div>
                  <div class="cb"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activeName == '操作记录'" class="setting-right-wrap wrap-boxes settings">
          <div class="page-settings">
            <div class="common-title">
              操作记录
              <span class="title-tips">通过查看登录时间、登录地点和设备可以判断账号是否正常</span>
              <span class="title-tips" style="color: #ff0000">注（只展示最近30天的登录历史记录）</span>
              <a href="javascript:void(0)" class="pull-right js-update-psw">不是我登录的？</a>
            </div>
            <div class="line"></div>
            <div class="setting">
              <div class="contentBox">
                <table class="oplog-list">
                  <tbody>
                    <tr>
                      <th>登录时间</th>
                      <th>城市</th>
                      <th>IP</th>
                      <th>登录设备</th>
                      <th>操作系统</th>
                    </tr>
                    <tr v-for="(v,i) in UserPage.czjl" :key="i">
                      <td>{{v.date}}</td>
                      <td>{{v.cs}}</td>
                      <td>{{v.IP}}</td>
                      <td>{{v.sj}}</td>
                      <td>{{v.window}}</td>
                    </tr>
                    <!-- UserPage.czjs -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activeName == '收件地址'" class="setting-right-wrap wrap-boxes settings">
          <div class="page-settings">
            <div class="common-title">收件地址</div>
            <div class="line"></div>
            <div class="setting">
              <div class="contentBox">
                <ul class="list-box clearfix">
                  <li @click="ssshow = true" class="add-address l">
                    <div class="add-box"><p class="icon-plus add-btn js-add-btn"></p><p class="add-text">添加新地址</p></div>
                  </li>
                  <li v-for="(v,i) in UserPage.edit" :key="i" class="l">
                    <div class="normal-icon" :class="{'show':v.mr}"><span class="triangle-topright"></span><i>默认</i></div>
                    <h3 class="name">{{v.name}}</h3>
                    <p class="phone clearfix"><span class="l detail-key">电话：</span><span class="l detail-value">{{v.phone}}</span></p>
                    <p class="area clearfix"><span class="l detail-key">地区：</span><span class="l detail-value">{{v.dq}}</span></p>
                    <p class="address-text clearfix"><span class="l detail-key">地址：</span><span class="l detail-value">{{v.dz}}</span></p>
                    <p class="zip-code clearfix"><span class="l detail-key">邮编：</span><span class="l detail-value">{{v.yb}}</span></p>
                    <div class="modify-box clearfix"><span class="js-delete-btn delete-btn r" @click="del">删除</span><span class="js-modify-btn modify-btn r" @click="edit">修改</span><span class="js-normal-btn normal-btn r ">设置为默认地址</span></div>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- 个人信息编辑框 -->
    <div class="moco-modal-blackout js-moco-modal-cancel" style="z-index: 900; opacity: 0.7; width: 100vw; height: 100vh" v-show="xxshow || ssshow || cz.isPass || cz.isPone || cz.isEmile"></div>
    <div v-show="xxshow" class="moco-modal-overlay" style="z-index: 901; opacity: 1">
      <div class="moco-modal-layer pera" style="z-index: 901; visibility: visible;">
        <div class="moco-modal-title"><div><span>编辑个人信息</span><a @click="xxshow = false" href="javascript:void(0)" class="moco-icon-close moco-modal-close fonticon js-modal-close"></a></div></div>
        <div class="moco-modal-inner">
          <div class="moco-modal-dialog" style="display: block">
            <div class="edit-info">
              <form class="js-wapper-form" id="profile">
                <div class="moco-form-group">
                  <label for="" class="moco-control-label">昵称：</label>
                  <div class="moco-control-input">
                    <input type="text" name="nickname" id="nick" autocomplete="off" data-validate="require-nick" class="moco-form-control" v-model="UserPages.userName" placeholder="请输入昵称"/>
                    <div class="rlf-tip-wrap errorHint color-red"></div>
                  </div>
                </div>
                <div class="moco-form-group">
                  <label for="" class="moco-control-label">职位：</label>
                  <div class="moco-control-input">
                    <select class="moco-form-control rlf-select" name="job" hidefocus="true" id="job" v-model="UserPages.zhiwei" data-validate="require-select">
                      <option value="">请选择职位</option>
                      <option value="18">Python工程师</option>
                      <option value="1">页面重构设计</option>
                      <option value="6">Web前端工程师</option>
                      <option value="5">JS工程师</option>
                      <option value="8">PHP开发工程师</option>
                      <option value="11">JAVA开发工程师</option>
                      <option value="7">移动开发工程师</option>
                      <option value="9">软件测试工程师</option>
                      <option value="10">Linux系统工程师</option>
                      <option value="3">产品经理</option>
                      <option value="21">软件工程师</option>
                      <option value="15">数据库工程师</option>
                      <option value="2">交互设计师</option>
                      <option value="4">UI设计师</option>
                      <option value="17">CG影视动画师</option>
                      <option value="16">全栈工程师</option>
                      <option value="20">算法工程师</option>
                      <option value="26">架构师</option>
                      <option value="27">资深架构师</option>
                      <option value="25">学生</option>
                    </select>
                    <div class="rlf-tip-wrap errorHint color-red"></div>
                  </div>
                </div>

                <div class="moco-form-group wlfg-wrap">
                  <label for="" class="moco-control-label h16 lh16">性别：</label>
                  <div class="moco-control-input rlf-group rlf-radio-group">
                    <label><input type="radio" hidefocus="true" value="0" name="sex"/>保密</label>
                    <label><input type="radio" hidefocus="true" value="1" checked="checked" name="sex"/>男</label>
                    <label><input type="radio" hidefocus="true" value="2" name="sex"/>女</label>
                    <div class="rlf-tip-wrap errorHint color-red"></div>
                  </div>
                </div>
                <div class="moco-form-group wlfg-wrap">
                  <label for="" class="moco-control-label">个性签名：</label>
                  <div class="moco-control-input">
                    <div class="pr">
                      <textarea v-model="UserPages.qm" name="aboutme" id="aboutme" rows="5" class="noresize js-sign moco-form-control"></textarea>
                      <p class="numCanInput js-numCanInput">还可以输入128个字符</p>
                    </div>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="moco-control-label" for=""></label>
                  <div class="moco-control-input">
                    <a href="javascript:void(0);" id="profile-submit" class="moco-btn moco-btn-blue marR10">确定</a>
                    <a @click="xxshow = false" href="javascript:void(0);" class="moco-btn moco-btn-normal js-modal-close">取消</a>
                    <p class="js-gerror g_error"></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dizhi v-show="ssshow" @close="ssshow = false"/>
    <pass v-show="cz.isPass" @close="cz.isPass = false" />
    <phone v-show="cz.isPone" @close="cz.isPone = false" />
    <Emile v-show="cz.isEmile" @close="cz.isEmile = false" />
  </div>
</template>

<script>
import Emile from "./model/Emile.vue";
import phone from "./model/phone.vue";
import pass from "./model/pass.vue";
import dizhi from "./model/dizhi.vue";

import { mapState } from "vuex";
import api from "../../ulti/ajax";

// import api from "../../../../ajax/index";
export default {
  components: {
    Emile,
    phone,
    dizhi,
    pass,
  },
  data() {
    return {
      activeName: "个人信息",
      List: ["个人信息", "账号绑定", "操作记录", "收件地址"],
      UserPage: {
        userName: "慕前端2067603",
        pid: "1234561234",
        image:
          "http://img1.sycdn.imooc.com/545846230001832502200220-200-200.jpg",
        qm: "个性签名",
        sex: "男",
        chengshi: "",
        zhiwei: "",
        UserMessage: {
          phone: "15100117859",
          emil: "488907729@Qq.com",
        },
        czjl:[
          {
            date:"2021.02.06 15:01",
            cs:"河北省石家庄市 联通",
            IP:"101.16.238.46",
            sj:"web",
            window:"Windows",
          },
          {
            date:"2021.02.06 15:01",
            cs:"河北省石家庄市 联通",
            IP:"101.16.238.46",
            sj:"web",
            window:"Windows",
          },
          {
            date:"2021.02.06 15:01",
            cs:"河北省石家庄市 联通",
            IP:"101.16.238.46",
            sj:"web",
            window:"Windows",
          }
        ],
        edit:[
          {
            name:"王明",
            phone:"18808331678",
            dq:"北京市朝阳区黑色森林",
            dz:"独阳正香江苏省正东路108号",
            yb:"050633",
            mr:true,
          },
          {
            name:"王明1",
            phone:"18808331678",
            dq:"北京市朝阳区黑色森林",
            dz:"独阳正香江苏省正东路108号",
            yb:"050633"
          },
        ]
      },
      UserPages: {
        userName: "慕前端2067603",
        qm: "",
        sex: "男",
        chengshi: "",
        zhiwei: "",
      },
      userinfo:{},

      // 操作
      cz: {
        isEmile: false,
        isPone: false,
        isPass: false,
      },
      update: {
        pass: "",
      },
      // 信息开关
      xxshow: false,
      // 收件信息开关
      ssshow: false,
      EmileText: "发送验证码",
      PoneText: "发送验证码",
      sendEmileUsername: "",

    };
  },

  computed: {
    ...mapState(["cache"]),
  },
  async created() {
    document.title='p8课堂-个人中心';
  },

  async mounted() {
    await this.getdata();
  },


  methods: {

    getdata() {

      api.getInfo().then(res2 => {
        console.log("222222222res2.data.user2222"+JSON.stringify(res2.data.user))
        this.userinfo=res2.data.user;

      });

    },




    del(){},
    edit(){},
    upEmile() {},
    sendEmile() {
      console.log(this.timerE);
      if (this.timerE) {
        return;
      }
      // 发送邮箱验证码
      // api.sendEmile(this.)
      let num = 60;
      this.EmileText = "重新发送(" + num + "s)";
      this.timerE = setInterval((e) => {
        this.EmileText = "重新发送(" + num + "s)";
        num--;
        if (num == 0) {
          clearInterval(this.timerE);
        }
      }, 1000);
    },
    sendPone() {},
    upEmile() {},
    upPone() {},
    upPass() {},
  },
};
</script>

<style>
@import url("../../assets/css/gr.css");
body {
  background: #fff !important;
}
.pera{
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.pagebtn {
  padding: 7px 15px;
  background: #008cc8;
  color: #fff;
  border-radius: 5px;
}

.moco-modal-layer {
  position: absolute;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  border-radius: 12px;
}

.moco-modal-layer.fixed {
  top: 20px !important;
  margin-bottom: 20px !important;
}

.moco-modal-title {
  margin-bottom: -8px;
  padding: 24px 32px 0;
  line-height: 20px;
}

.moco-modal-title div {
  font-size: 16px;
  font-weight: bold;
  color: #1c1f21;
}

.moco-modal-draggable {
  cursor: move;
}

.moco-modal-title a.moco-modal-close {
  font-size: 24px;
  position: absolute;
  right: 32px;
  top: 24px;
  color: #b2b8bd;
  display: block;
  text-decoration: none;
}

.moco-modal-title a.moco-modal-close:hover {
  color: #1c1f21;
}

.moco-modal-inner .moco-modal-dialog {
  padding: 32px;
}

.moco-modal-inner .moco-modal-wrap {
  box-sizing: border-box;
  width: 320px;
  padding: 32px;
  line-height: 1;
}

.moco-modal-content {
  font-size: 16px;
  font-weight: bold;
  color: #1c1f21;
  line-height: 24px;
}

.moco-modal-info {
  padding-top: 8px;
  font-size: 12px;
  color: #545c63;
  line-height: 16px;
}

.moco-modal-info a {
  color: #1481b8;
  text-decoration: none;
}

.moco-modal-info a:hover {
  color: #0f618a;
}

.moco-modal-btns {
  padding-top: 32px;
  text-align: right;
}

.moco-modal-btns .moco-btn {
  margin-left: 12px;
  min-width: 80px;
}

.moco-tip-layer {
  position: absolute;
  z-index: 99999;
  font-size: 12px;
  line-height: 16px;
  color: #1c1f21;
  padding: 12px 16px 12px 12px;
  border-radius: 8px;
}

.moco-prompt-layer {
  font-size: 12px;
  line-height: 16px;
  color: #1c1f21;
  padding: 12px 16px 12px 12px;
  border-radius: 8px;
}

.moco-modal-layer .moco-icon-tick-revert,
.moco-modal-layer .moco-icon-ques-revert,
.moco-modal-layer .moco-icon-point-revert {
  float: left;
  margin-right: 4px;
  font-size: 16px;
}

.moco-modal-layer .moco-icon-tick-revert {
  color: #1fad4e;
}

.moco-modal-layer .moco-icon-ques-revert {
  color: #1c1f21;
}

.moco-modal-layer .moco-icon-point-revert {
  color: #f20d0d;
}
.moco-modal-title a.moco-modal-close {
  font-family: "icomoon" !important;
}

.contentBox .list-box {
  padding: 0 4px;
  margin-top: 24px;
}

.contentBox .list-box li {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 24px;
  width: 296px;
  height: 227px;
  border: 1px solid #d0d6d9;
  box-sizing: border-box;
}

.contentBox .list-box li h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #07111b;
  font-weight: 700;
}

.contentBox .list-box li .address-text,
.contentBox .list-box li .area,
.contentBox .list-box li .phone,
.contentBox .list-box li .zip-code {
  font-size: 14px;
  color: #4d555d;
}

.contentBox .list-box li .address-text span,
.contentBox .list-box li .area span,
.contentBox .list-box li .phone span,
.contentBox .list-box li .zip-code span {
  display: block;
}

.contentBox .list-box li .address-text .detail-value,
.contentBox .list-box li .area .detail-value,
.contentBox .list-box li .phone .detail-value,
.contentBox .list-box li .zip-code .detail-value {
  max-width: 188px;
  word-break: break-word;
}

.contentBox .list-box li .modify-box {
  display: none;
  position: absolute;
  font-size: 12px;
  color: #f01414;
  bottom: 16px;
  right: 20px;
}

.contentBox .list-box li .modify-box span {
  cursor: pointer;
}

.contentBox .list-box li .modify-box .normal-btn {
  display: none;
}

.contentBox .list-box li .modify-box .modify-btn {
  margin-right: 22px;
  margin-left: 80px;
}

.contentBox .list-box li .normal-icon {
  display: none;
  position: absolute;
  top: 45px;
  right: -1px;
}

.contentBox .list-box li .normal-icon .triangle-topright {
  width: 0;
  height: 0;
  border-top: 48px solid #00b43c;
  border-left: 48px solid transparent;
}

.contentBox .list-box li .normal-icon i {
  position: absolute;
  top: -35px;
  right: 4px;
  transform: rotate(45deg);
  color: #fff;
  font-style: normal;
  font-size: 12px;
}

.contentBox .list-box li:hover .modify-box {
  display: block;
}

.contentBox .list-box li:nth-child(3n) {
  margin-right: 0;
}

.contentBox .list-box .add-address {
  text-align: center;
}

.contentBox .list-box .add-address .add-btn {
  width: 50px;
  height: 50px;
  margin: 46px auto 0;
  text-align: center;
  color: #fff;
  background: #d9dde1;
  font-size: 20px;
  font-weight: 700;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
}

.contentBox .list-box .add-address .add-text {
  text-align: center;
  font-size: 14px;
  color: #4d555d;
  margin-top: 18px;
}

.contentBox .list-box .show {
  display: block !important;
}

.contentBox .list-box .hide {
  display: none !important;
}
. .oplog-list {
  width: 100%;
  margin-top: 24px;
}

.oplog-list td,
.oplog-list th {
  text-align: center;
  line-height: 48px;
}

.oplog-list th {
  background-color: #f3f5f7;
}

.oplog-list td {
  border-bottom: 1px solid #d9dde1;
}

.common-title a {
  color: #008cc8;
  font-size: 12px;
}

.moco-control-label {
  width: 118px !important;
}
.oplog-list {
  width: 100%;
}

.address-box {
  margin-top: 10px;
}

.address-box .wlfg-wrap .label-name {
  float: left;
  width: 80px;
  text-align: right;
  height: 36px;
  line-height: 36px;
  color: #07111b;
  font-weight: 700;
  font-size: 14px;
}

.address-box .wlfg-wrap .rlf-group {
  float: left;
  width: 298px;
  margin-left: 8px;
  color: #60686b;
}

.address-box .wlfg-wrap .rlf-group input {
  font-size: 14px;
}

.address-box .wlfg-wrap .rlf-group .error-tip {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #ef1300;
}

.address-box .wlfg-wrap .rlf-group .numCanInput {
  right: 12px;
  bottom: 22px;
}

.address-box .wlfg-wrap .profile-address select {
  float: left;
  margin-right: 11px;
  width: 92px;
  height: 36px;
}

.address-box .wlfg-wrap .profile-address .mr0 {
  margin-right: 0;
}

.address-box .wlfg-wrap textarea.moco-form-control {
  height: 70px !important;
}

.address-box .wlfg-wrap .moco-form-control {
  border: 1px solid #d9dde1 !important;
}
</style>
