<template>
  <div class="moco-modal-overlay" style="z-index: 901; opacity: 1">
     <div class="moco-modal-layer pera" style="z-index: 901; visibility: visible;">
        <div class="moco-modal-title">
          <div>
            <span>修改手机号</span>
            <a  @click="close" href="javascript:void(0)" class="moco-icon-close moco-modal-close js-modal-close"></a>
          </div>
        </div>
        <div class="moco-modal-inner">
          <div class="moco-modal-dialog" style="display: block">
            <div class="yanzheng-id">
              <div class="keybox"><i class="icon-key"></i></div>
              <p class="font1 mb20">重置手机号</p>
              <div class="dialogBox yanZhengBox" style="width: 430px">
                <div class="moco-form-group">
                  <div class="moco-control-input">
                    <input
                      v-model="phone"
                      type="text"
                      placeholder="请输入手机号"
                      class="js-pwd moco-form-control"
                      data-validate="require-password"
                    />
                    <div class="moco-control-tip errorHint color_red"></div>
                  </div>
                    <label style="text-align:left;padding:0" for="inputEmail3" class="moco-control-label"
                    >
                    <button @click="sendEmile" class="btn pagebtn">{{EmileText}}</button>
                    </label
                  >
                </div>
                <div class="moco-form-group">
                  <div class="moco-control-input">
                    <input
                      v-model="passs"
                      type="text"
                      placeholder="请确认验证码"
                      class="js-pwd moco-form-control"
                      data-validate="require-password"
                    />
                    <div class="moco-control-tip errorHint color_red"></div>
                  </div>
                </div>
                <div class="moco-form-group">
                  <label for="inputEmail3" class="moco-control-label"></label>
                  <div class="moco-control-input">
                    <a
                      @click="upEmile"
                      href="javascript:void(0);"
                      class="moco-btn moco-btn-blue js-sf-submit"
                      >确定</a
                    >
                    <a
                      @click="close"
                      href="javascript:void(0);"
                      class="moco-btn moco-btn-normal js-modal-close"
                      >取消</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      passs: "",
      EmileText:"发送验证码"
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    upEmile() {},
    sendEmile(){
      if(this.timerE){
        return ;
      }
      let num = 60;
      this.EmileText = '重新发送('+num+'s)';
      this.timerE = setInterval((e)=>{
        this.EmileText = '重新发送('+num+'s)';
        num --;
        if(num==0){
          clearInterval(this.timerE)
        }
      },1000)
    }
  },
};
</script>

<style>
</style>