<template>
   <div class="moco-modal-overlay" style="z-index: 901; opacity: 1" >
      <div
        class="moco-modal-layer fixed pera"
        style="
          z-index: 901;
          visibility: visible;
          width: 488px;
          height: 509px;
        "
      >
        <div class="moco-modal-title">
          <div>
            <span>收件地址</span
            ><a
              @click="close"
              href="javascript:void(0)"
              class="moco-icon-close moco-modal-close js-modal-close"
            ></a>
          </div>
        </div>
        <div class="moco-modal-inner">
          <div class="moco-modal-dialog" style="display: block">
            <div class="address-box">
              <form id="address-form">
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for="name">姓名：</label>
                  <div class="rlf-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      data-minlength="2"
                      data-maxlength="25"
                      autocomplete="off"
                      data-validate="require-string"
                      class="moco-form-control"
                      value=""
                      placeholder="长度不能超过25个字符"
                    />
                    <p class="error-tip errorHint"></p>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for="phone">电话：</label>
                  <div class="rlf-group">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autocomplete="off"
                      data-validate="require-mobile-phone"
                      class="moco-form-control"
                      value=""
                      placeholder="输入您的手机号"
                    />
                    <p class="error-tip errorHint"></p>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for="province-select">城市：</label>
                  <div class="rlf-group profile-address">
                    <select
                      id="province-select"
                      class="moco-form-control"
                      hidefocus="true"
                      data-validate="require-select"
                    >
                      <option value="0">省</option>
                    </select>
                    <select
                      class="moco-form-control"
                      id="city-select"
                    >
                      <option value="0">市</option>
                    </select>
                    <select
                      class="moco-form-control mr0"
                      id="area-select"
                    >
                      <option value="0">区县</option>
                    </select>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for="addrdetail">详细地址：</label>
                  <div class="rlf-group">
                    <div class="pr">
                      <textarea
                        name="addrdetail"
                        id="addrdetail"
                        class="noresize js-sign moco-form-control"
                        placeholder="详细填写你的收件地址，如街道名称、门牌号码、楼层、房间号等信息"
                      ></textarea>
                      <div class="cb"></div>
                      <p class="error-tip errorHint"></p>
                    </div>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for="zipcode">邮编：</label>
                  <div class="rlf-group">
                    <input
                      type="text"
                      name="zipcode"
                      id="zipcode"
                      autocomplete="off"
                      data-validate="require-notempty"
                      class="moco-form-control"
                      value=""
                      placeholder="请输入邮编"
                    />
                    <p class="error-tip errorHint"></p>
                  </div>
                </div>
                <div class="wlfg-wrap clearfix">
                  <label class="label-name" for=""></label>
                  <div class="rlf-group">
                    <a
                      href="javascript:void(0);"
                      hidefocus="true"
                      id="submit"
                      class="moco-btn moco-btn-blue marR10"
                      >保存</a
                    >
                    <a
                      href="javascript:void(0);"
                      hidefocus="true"
                      aria-role="button"
                      @click="close"
                      class="moco-btn moco-btn-normal js-modal-close"
                      >取消</a
                    >
                    <p
                      class="rlf-tip-wrap errorHint color-red js-all-errtip"
                    ></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
methods: {
    close(){
        this.$emit('close')
    }
},
}
</script>

<style>

</style>